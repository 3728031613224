<mat-toolbar color="primary">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="logo-container">
        <img src="/assets/img/logo-mrsms.png" mat-card-avatar alt="MrPay Logo" 
            class="logo-menu" routerLink="/dashborad"/>
    </div>
    
    <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li *ngIf="adminMenu">
                <button mat-button routerLink="/customers" routerLinkActive="active">Clienti</button>
            </li>
            <span class="menu-spacer"></span>
            <li *ngIf="adminMenu">
                <button mat-button routerLink="/suppliers" routerLinkActive="active">Fornitori</button>
            </li>
            <span class="menu-spacer"></span>
            <li *ngIf="adminMenu">
                <button mat-button routerLink="/pricelist" routerLinkActive="active">Listini</button>
            </li>
            <li *ngIf="adminMenu">
                <button mat-button [matMenuTriggerFor]="storico"><mat-icon>more_vert</mat-icon>Storico</button>
                <mat-menu #storico="matMenu">
                    <button mat-menu-item routerLink="/history/customer" routerLinkActive="active">Clienti</button>
                    <button mat-menu-item routerLink="/history/balance" routerLinkActive="active">Credito</button>
                    <button mat-menu-item routerLink="/history/supplier" routerLinkActive="active">Fornitori</button>
                    <button mat-menu-item routerLink="/history/pricelist" routerLinkActive="active">Listini</button>
                    <button mat-menu-item routerLink="/history/request" routerLinkActive="active">Richieste</button>
                </mat-menu>
            </li>
            <li>
                <button mat-button [matMenuTriggerFor]="azioni"><mat-icon>sim_card</mat-icon>Azioni</button>
                <mat-menu #azioni="matMenu">
                    <button mat-menu-item routerLink="/azioni/send-sms" routerLinkActive="active">Invia Sms</button>
                    <button mat-menu-item routerLink="/azioni/check-portability" routerLinkActive="active">Portabilità</button>
                </mat-menu>
            </li>
            <li>
                <button mat-button [matMenuTriggerFor]="user"><mat-icon>arrow_drop_down</mat-icon>{{userLogged.username}}</button>
                <mat-menu #user="matMenu">
                    <button mat-menu-item routerLink="/user/profile" routerLinkActive="active">Profilo</button>
                    <button mat-menu-item routerLink="/user/report" routerLinkActive="active">Report</button>
                    <button mat-menu-item routerLink="/user/sms" routerLinkActive="active">Sms</button>
                    <button mat-menu-item (click)="logout()" routerLinkActive="active">Esci</button>
                </mat-menu>
            </li>
        </ul>
    </div>
</mat-toolbar>