import { Injectable } from "@angular/core";
import { PaginationParams, Supplier } from "../models";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})

export class SupplierService {

    constructor(private api: ApiService) { }

    loadSuppliers(params: PaginationParams){
        return this.api.post(`/supplier/suppliers`, params);
    }

    create(supplier: Supplier){
        return this.api.post(`/supplier/create-supplier`, supplier);
    }

    edit(id: number, supplier: Supplier){
        return this.api.put(`/supplier/supplier/${id}`, supplier);
    }

    delete(id: number){
        return this.api.delete(`/supplier/supplier/${id}`);
    }

    supplierPricelist(params: PaginationParams){
        return this.api.post(`/supplier/pricelist`, params);
    }
}