import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { JwtService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private jwtService: JwtService) {}

  canActivate() {
    if (this.jwtService.isTokenExpired()){
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }

  canActivateChild(){
    if (this.jwtService.isTokenExpired()){
      this.router.navigate(['auth/login']);
      return false;
    }
    return true;
  }
}