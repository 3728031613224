import { Injectable } from '@angular/core';
import { AppSettings } from '../common';
import { Customer, CustomerEdit, Login, PaginationParams, Recharge, ResetPassword } from '../models';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { LocalStorageService } from './localStorage.service';

@Injectable({
    providedIn: 'root',
})

export class CustomerService {
    
    constructor(private api: ApiService, private jwtService: JwtService, private localStorageService: LocalStorageService){ }

    login(login: Login){
        return this.api.post(`/account/login`, login);
    }

    logout(){
        this.localStorageService.removeKey(AppSettings.User);
        return this.jwtService.destroyToken();
    }

    loadCustomers(params: PaginationParams){
        return this.api.post(`/account/customers`, params);
    }

    create(customer: Customer){
        return this.api.post(`/account/create-customer`, customer);
    }

    edit(id: number, customer: CustomerEdit){
        return this.api.put(`/account/customer/${id}`, customer);
    }

    delete(id: number){
        return this.api.delete(`/account/customer/${id}`);
    }

    resetPassword(resetPassword: ResetPassword){
        return this.api.post(`/account/reset-password`, resetPassword);
    }

    recharge(recharge: Recharge){
        return this.api.post('/account/recharge', recharge);
    }

    profile(){
        return this.api.get('/account/profile');
    }
	
    monthly(){
        return this.api.get('/account/profile');
    }
}