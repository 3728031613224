<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">
    <mat-card-header>
      <img src="/assets/img/logo-mrsms.png" mat-card-avatar alt="MrPay Logo"/>
      <mat-card-title>Accedi</mat-card-title>
    </mat-card-header>
    <form class="example-form" [formGroup]="loginForm">
      <mat-card-content>
        
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Username" formControlName="username" required>
          <mat-error *ngIf="f['username']?.invalid">
            <span *ngIf="f['username']?.errors?.['required']">
              Username è obbligatorio
            </span>
            <span *ngIf="f['username']?.errors?.['minLength']">
              La lunghezza minima è di 6 caratteri
            </span>
            <span *ngIf="f['username']?.errors?.['maxLength']">
              La lunghezza massima è di 255 caratteri
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Password" type="password" formControlName="password" required>
          <mat-error *ngIf="f['password']?.invalid">
            <span *ngIf="f['password']?.errors?.['required']">
              Password è obbligatorio
            </span>
            <span *ngIf="f['password']?.errors?.['minLength']">
              La lunghezza minima è di 8 caratteri
            </span>
            <span *ngIf="f['password']?.errors?.['maxLength']">
              La lunghezza massima è di 255 caratteri
            </span>
          </mat-error>
        </mat-form-field>

      </mat-card-content>

      <button mat-stroked-button color="primary" class="btn-block" 
        [disabled]="!!loginForm?.invalid" (click)="login()">Accedi</button>

    </form>
  </mat-card>
</div>