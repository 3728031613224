import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, Customer, CustomerService, LocalStorageService } from 'src/app/core';
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent  {
  @Output() sidenavClose = new EventEmitter();
  userLogged: Customer;
  adminMenu: boolean;

  constructor(private customerService: CustomerService, private router: Router,
    private localStorageService: LocalStorageService){
      this.userLogged = localStorageService.getKey<Customer>(AppSettings.User);
      this.adminMenu = environment.adminUsername.includes(this.userLogged.username);
    }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

  logout(){
    this.customerService.logout();
    return this.router.navigate(['auth/login']);
  }
}