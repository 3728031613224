<mat-nav-list>
    <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">MrSMS</span>
    </a>
    <a mat-list-item routerLink="/customers" (click)="onSidenavClose()" *ngIf="adminMenu" routerLinkActive="active-sidenav">
        <mat-icon>people</mat-icon> <span class="nav-caption">Clienti</span>   
    </a>
    <a mat-list-item routerLink="/suppliers" (click)="onSidenavClose()" *ngIf="adminMenu" routerLinkActive="active-sidenav">
        <mat-icon>account_balance</mat-icon><span class="nav-caption">Fornitori</span>
    </a>
    <a mat-list-item routerLink="/pricelist" (click)="onSidenavClose()" *ngIf="adminMenu" routerLinkActive="active-sidenav">
        <mat-icon>account_balance</mat-icon><span class="nav-caption">Listini</span>
    </a>
    <mat-list-item [matMenuTriggerFor]="storico" *ngIf="adminMenu">
        <mat-icon>more_vert</mat-icon><a matline>Storico</a>
        <mat-menu #storico="matMenu">
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/history/customer" routerLinkActive="active-sidenav">Clienti</button>
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/history/balance" routerLinkActive="active-sidenav">Credito</button>
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/history/supplier" routerLinkActive="active-sidenav">Fornitori</button>
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/history/pricelist" routerLinkActive="active-sidenav">Listini</button>
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/history/request" routerLinkActive="active-sidenav">Richieste</button>
          </mat-menu>
    </mat-list-item>

    <mat-list-item [matMenuTriggerFor]="azioni">
        <mat-icon>sim_card</mat-icon><a matline>Azioni</a>
        <mat-menu #azioni="matMenu">
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/azioni/send-sms" routerLinkActive="active-sidenav">Invia Sms</button>
            <button mat-menu-item (click)="onSidenavClose()" routerLink="/azioni/check-portability" routerLinkActive="active-sidenav">Portabilità</button>
          </mat-menu>
    </mat-list-item>

    <mat-list-item [matMenuTriggerFor]="user">
        <mat-icon>arrow_drop_down</mat-icon><a matline>{{userLogged.username}}</a>
        <mat-menu #user="matMenu">
            <button mat-menu-item routerLink="/user/profile" routerLinkActive="active-sidenav">Profilo</button>
            <button mat-menu-item routerLink="/user/report" routerLinkActive="active-sidenav">Report</button>
            <button mat-menu-item routerLink="/user/sms" routerLinkActive="active-sidenav">Sms</button>
            <button mat-menu-item (click)="onSidenavClose(); logout()" routerLinkActive="active-sidenav">Esci</button>
          </mat-menu>
    </mat-list-item>
</mat-nav-list>