import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})

export class TypesService {
    
    constructor(private api: ApiService) { }

    getOperationTypes() {
        return this.api.get(`/types/operation-type`);
    }

    getNotificationTypes() {
        return this.api.get(`/types/notification-type`);
    }

    getPriceTypes() {
        return this.api.get(`/types/price-type`);
    }

    getProtocolTypes() {
        return this.api.get(`/types/protocol-types`);
    }

    getPrefixes() {
        return this.api.get(`/types/prefixes`);
    }

    getCustomers() {
        return this.api.get(`/types/customer-types`);
    }

    getSuppliers() {
        return this.api.get(`/types/supplier-types`);
    }
}