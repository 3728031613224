import { Injectable } from "@angular/core";
import { Sms } from "../models";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(private api: ApiService) { }

    sendSms(sms: Sms){
        return this.api.post(`/notifications/send-sms`, sms);
    }

    sendSmsUnicode(sms: Sms){
        return this.api.post(`/notifications/send-sms-unicode`, sms);
    }

    checkPortability(phoneNumber: string){
        return this.api.post(`/notifications/check-portability`, phoneNumber);
    }
}