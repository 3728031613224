import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppSettings, Customer, CustomerService, JwtService, LocalStorageService, Login, Response } from 'src/app/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    username: ['', [Validators.required,
      Validators.minLength(6), Validators.maxLength(255)]],
    password: ['', [Validators.required, 
      Validators.minLength(8), Validators.maxLength(255)]],
  });

  constructor(private fb: FormBuilder, private router: Router,  
    private jwtService: JwtService, private customerService: CustomerService,
    private localStorageService: LocalStorageService) {}

  ngOnInit() {}

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  login() {
    var login = <Login>{
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };

    return this.customerService.login(login).subscribe({
      next:
      (res: Response<Customer>) => {
        var user = res.result;
        this.localStorageService.saveKey(AppSettings.User, user);
        this.jwtService.saveToken(user.token);
        this.router.navigate(['dashboard']);
      },
      error:
      (err) => {
        console.log(err);
      }
    });

  }
}