import { Injectable } from "@angular/core";
import { PaginationParams, PricelistCost, PriceListCreate } from "../models";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})

export class PriceListService {

    constructor(private api: ApiService) { }

    loadPriceLists(params: PaginationParams){
        return this.api.post(`/pricelist/pricelists`, params);
    }

    create(priceList: PriceListCreate){
        return this.api.post(`/pricelist/pricelist-create`, priceList);
    }

    edit(id: number, priceList: PriceListCreate){
        return this.api.put(`/pricelist/pricelist/${id}`, priceList);
    }

    delete(id: number){
        return this.api.delete(`/pricelist/pricelist/${id}`);
    }

    loadCost(cost: PricelistCost){
        return this.api.post(`/pricelist/cost`, cost);
    }
}