import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, Customer, CustomerService, LocalStorageService } from 'src/app/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Output() public sidenavToggle = new EventEmitter();
  userLogged: Customer;
  adminMenu: boolean;

  constructor(private customerService: CustomerService, private router: Router, 
    private localStorageService: LocalStorageService)
  {
	  if (!environment.production)
		  console.log("Is Production:" + environment.production);

    this.userLogged = localStorageService.getKey<Customer>(AppSettings.User);
    this.adminMenu = environment.adminUsername.includes(this.userLogged.username);
  }
  
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  logout(){
    this.customerService.logout();
    return this.router.navigate(['auth/login']);
  }
}