import { Injectable } from "@angular/core";
import { PaginationParams } from "../models";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root',
})

export class HistoryService {

    constructor(private api: ApiService) { }

    apirequests(params: PaginationParams){
        return this.api.post(`/history/requests`, params);
    }

    customers(params: PaginationParams){
        return this.api.post(`/history/customer`, params);
    }

    suppliers(params: PaginationParams){
        return this.api.post(`/history/supplier`, params);
    }

    pricelists(params: PaginationParams){
        return this.api.post(`/history/pricelist`, params);
    }

    customersbalance(params: PaginationParams){
        return this.api.post(`/history/balance`, params);
    }
}