import { Injectable } from '@angular/core';
import { Customer, PaginationParams, Supplier } from '../models';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})

export class ReportsService {
    
    constructor(private api: ApiService){ }

    customerInvoicing(params: PaginationParams){
        return this.api.post(`/reports/customer`, params);
    }		

    profileInvoicing(params: PaginationParams){
        return this.api.post(`/reports/profile`, params);
    }
	
    supplierInvoicing(params: PaginationParams) {
        return this.api.post('/reports/supplier', params);
    }

    smsHistory(params: PaginationParams){
        return this.api.post('/reports/sms', params);
    }
}