import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
  
import { JwtService } from '../services';
  
@Injectable({
    providedIn: 'root',
})

export class TokenInterceptor implements HttpInterceptor {
    constructor(private jwtService: JwtService) {}
  
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      req = req.clone({
          setHeaders:{
            'Content-Type': 'application/json',
             Accept: 'application/json',
          }
      });
  
      const token = this.jwtService.getToken();
  
      if (token) {
        req  =req.clone({
            setHeaders:{
                Authorization : `Bearer ${token}`
            }
        });
      }
      return next.handle(req);
    }
}