import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiService, LoaderService, JwtService, CustomerService, SupplierService, TypesService, PriceListService, 
         HistoryService, NotificationService, LocalStorageService, ReportsService } from "./services";
import { BaseUrlInterceptor, ErrorInterceptor, LoaderInterceptor, TokenInterceptor } from "./interceptors";
import { AuthGuard } from "./guards";

const services:any[] = 
  [ApiService, LoaderService, JwtService, AuthGuard, CustomerService, 
   SupplierService, TypesService, PriceListService, HistoryService,
   NotificationService, LocalStorageService, ReportsService];

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
          {
            provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true
          },
          {
            provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true
          },
          {
            provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true
          },
          {
            provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
          },
          ...services
	],
    declarations: []
})
export class CoreModule { }