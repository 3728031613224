import { Injectable } from "@angular/core";
import { AppSettings } from "../common";

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    getKey<T>(key: string): T {
		try {
            return JSON.parse(window.localStorage[key]);
        } catch (err) {
            this.removeKey(AppSettings.JwtToken);
            return { } as T;
        }
    }

    saveKey<T>(key: string, value: T){
        window.localStorage[key] =  JSON.stringify(value);
    }

    removeKey(key:string){
        window.localStorage.removeItem(key);
    }
}