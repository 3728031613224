import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
  } from '@angular/common/http';
  import { Injectable } from '@angular/core';
  import { Router } from '@angular/router';
  import { ToastrService } from 'ngx-toastr';
  import { Observable, throwError } from 'rxjs';
  import { retry, catchError, map } from 'rxjs/operators';
  //import { AppSettings } from '../common/index';
  
  /*
    Adds a default error handeler to all requests.
  */
  @Injectable({
    providedIn: 'root',
  })
  export class ErrorInterceptor implements HttpInterceptor {
    constructor(public router: Router, public toasterService: ToastrService) {}
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        //Retry
        //retry(AppSettings.RetryApiCall),
        //Response
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('event--->>>', event);
          }
          return event;
        }),
        //Error
        catchError((err: HttpErrorResponse) => {
          let title = '';
          let errorMessage = '';
          if (err.error instanceof ErrorEvent) {
            // client-side error
            title = 'Error Client'
            errorMessage = `${err.message}`;
          } else {
            // server-side error
            title = 'Error'
            errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
  
            switch (err.status) {
              case 0: //api not working
                errorMessage = `API server not reachable, please try again later.`;
                break;
  
              case 400: //Bad request
                errorMessage = err.error.errors.join(",")
                break;
  
              case 401: //login
                errorMessage = err.error.errors.join(",")
                this.router.navigateByUrl('/login');
                break;
  
              case 403: //forbidden
                title = "Forbidden"
                errorMessage = 'The requested resource is forbidden'
                this.router.navigateByUrl('/not-found');
                break;

              case 500: //internal server error
                errorMessage = 'The server is not responding';
                break;

              default:
                errorMessage = `An error has occured, please try again.`;
                break;
            }
          }
          this.toasterService.error(errorMessage, title);
          return throwError(() => errorMessage);
        })
      );
    }
  }